// eslint-disable-next-line import/no-cycle
import { get, post } from '@/utils/axios';

interface APIProps {
  getInfo: (params: any) => Promise<any>
  sendBindCode: (params: any) => Promise<any>
  confirmBinding: (params: any) => Promise<any>
}
const API: APIProps = {
  // 获取用户信息
  getInfo: (params: any) => post('/hasee/bifrost/noLoginSignCard/getAuthInfo', params),
  // 发送绑卡短信（度小满）
  sendBindCode: (params: any) => post('/hasee/bifrost/noLoginSignCard/send', params),
  // 确认绑卡（度小满）
  confirmBinding: (params: any) => post('/hasee/bifrost/noLoginSignCard/sign', params),
};
export default API;
